import { emailStandard } from "@/common/helper/custom-validator";
import { ServiceProviderSignUp } from "@/common/model/serviceprovider/service-provider-model";
import ServiceProviderServices from "@/common/services/service-provider-service";
import useVuelidate from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { filter, find, orderBy } from "lodash";
import { DateTime } from "luxon";
import { defineComponent } from "vue";
import { TimeZoneModel } from "../common/model/time-zone-model";
import { useQuasar } from 'quasar'
import { Enums } from "@/common/helper/enum";

export default defineComponent({
    name: "singup",
    setup: () => ({ v$: useVuelidate(), q$ : useQuasar() }),

    /************************************/
    /* Property Section *
      /************************************/

    computed: {
        isServiceProviderNameInvalid(): boolean {
            if (this.v$.serviceProvider && this.v$.serviceProvider.serviceProviderName && this.v$.serviceProvider.serviceProviderName.$dirty) {
                return this.v$.serviceProvider.serviceProviderName.$invalid;
            }

            return false;
        },

        isTimeZoneInvalid(): boolean {
            if (this.v$.serviceProvider && this.v$.serviceProvider.timeZone && this.v$.serviceProvider.timeZone.$dirty) {
                return this.v$.serviceProvider.timeZone.$invalid;
            }

            return false;
        },

        isFirstNameInvalid(): boolean {
            if (this.v$.serviceProvider && this.v$.serviceProvider.firstName && this.v$.serviceProvider.firstName.$dirty) {
                return this.v$.serviceProvider.firstName.$invalid;
            }

            return false;
        },

        isTechnicianCountInvalid(): boolean {
            if (this.v$.serviceProvider && this.v$.serviceProvider.noOfTechnicians && this.v$.serviceProvider.noOfTechnicians.$dirty) {
                return this.v$.serviceProvider.noOfTechnicians.$invalid;
            }

            return false;
        },

        isLastNameInvalid(): boolean {
            if (this.v$.serviceProvider && this.v$.serviceProvider.lastName && this.v$.serviceProvider.lastName.$dirty) {
                return this.v$.serviceProvider.lastName.$invalid;
            }

            return false;
        },

        isEmailInvalid(): boolean {
            if (this.v$.serviceProvider && this.v$.serviceProvider.email && this.v$.serviceProvider.email.$dirty) {
                return this.v$.serviceProvider.email.$invalid;
            }

            return false;
        },

        showRequiredValidationErrorForEmail(): boolean {
            if (this.isEmailInvalid) {
                return this.v$.serviceProvider.email.required.$invalid
            }
            return false;
        },
        showEmailValidationErrorForEmail(): boolean {
            if (this.isEmailInvalid) {
                return this.v$.serviceProvider.email.email.$invalid;
            }
            return false;
        },
        showEmailStandardValidationErrorForEmail(): boolean {
            if (this.isEmailInvalid) {
                return this.v$.serviceProvider.email.emailStandard.$invalid;
            }
            return false;
        }

    },

    /************************************/
    /* Watch Section *
      /************************************/

    watch: {
       
    },

    /************************************/
    /* Data Object Section *
      /************************************/

    data() {
        return {
            timeZoneList: [] as TimeZoneModel[],

            serviceProvider: {
                serviceProviderName: "" as string,
                timeZone: "" as string,
                firstName: "" as string,
                lastName: "" as string,
                email: "" as string,
                serviceProviderType: 1 as number,
                turnstileToken: "" as string,
                promotionCode: "" as string,
                isLoginWithAzureAd: false as boolean,
                phone: "" as string,
                noOfTechnicians: null as any
            } as ServiceProviderSignUp.ServiceProvider,

            widgetId: null,
            isSubmitting: false as boolean,
            isSignupCompleted: false as boolean,
            willAccountLoginWithAzureAD: false as boolean,
            technicianOptions: [ 
                { text: 'Just me', value: Enums.NoOfTechnicians.JustMe },
                { text: '2-4', value: Enums.NoOfTechnicians.TwotoFour },
                { text: '5-9', value: Enums.NoOfTechnicians.FivetoNine },
                { text: '10-19', value: Enums.NoOfTechnicians.tentoNineteen },
                { text: '20+', value: Enums.NoOfTechnicians.tweentyPlus },

            ],
            serviceProviderTypeOptions: [
                { text: 'Managed Service Provider', value: 1 },
                { text: 'Enterprise', value: 2 },

            ]
        }
    },

    /************************************/
    /* Validation Section *
      /************************************/

    validations: {
        serviceProvider: {
            serviceProviderName: { required },
            firstName: { required },
            lastName: { required },
            email: { required, email, emailStandard },
            serviceProviderType: { required },
            timeZone: { required },
            noOfTechnicians: { required },
        },

        $validationGroups: ['serviceProvider'],
    },

    /************************************/
    /* Method Section *
      /************************************/

    methods: {

        goToLoginPage() {
            let signInRedirectURI = import.meta.env.VITE_goreloWebBaseURL;
            window.location.replace(signInRedirectURI);
        },


        async signupFormSubmit() {

            console.log(this.serviceProvider);

            this.isSubmitting = true;
            this.v$.serviceProvider!.$touch();
            if (this.v$.serviceProvider!.$invalid) {
                this.isSubmitting = false;
                return;
            }

            try {
                await ServiceProviderServices.saveServiceProvider(this.serviceProvider);
                this.willAccountLoginWithAzureAD = this.serviceProvider.isLoginWithAzureAd;
                this.clearFormData();
                this.v$.serviceProvider!.$reset();
                this.isSignupCompleted = true;
               
            }
            catch (e) {
                console.error("Error in signup process");
                console.error(e);

                if (e != null && e.response != null && e.response.data != null && Array.isArray(e.response.data) && e.response.data.length > 0) {
                    this.q$.notify({
                        message: e.response.data[0].errorMessage,
                        color: 'red',
                        position: "top"
                    })
                }
            }
            finally {
                this.isSubmitting = false;
                this.resetTurnstile();
            }
        },


        touchValidation(propertyName: string) {
            if (this.v$.serviceProvider && this.v$.serviceProvider[propertyName]) {
                this.v$.serviceProvider[propertyName].$touch();
            }
        },

        clearFormData() {
            this.serviceProvider.serviceProvideName = "";
            this.serviceProvider.firstName = "";
            this.serviceProvider.lastName = "";
            this.serviceProvider.email = "";
            this.serviceProvider.timeZone = "";
        },

        status(validation: any) {
            return {
                error: validation.$error,
                dirty: validation.$dirty,
            };
        },

        resetTurnstile() {
            if ((window as any).turnstile && this.widgetId) {
                (window as any).turnstile.reset(this.widgetId);
            }
        },

        async fetchTimeZoneList() {
            try {
                let response: TimeZoneModel[] = await ServiceProviderServices.getServieproviderTimeZoneList();

                for (let i = 0; i < response.length; i++) {
                    let zone: string = DateTime.utc()
                        .setZone(response[i].value)
                        .toFormat("ZZ");
                    response[i].nameWithZone = "(" + zone + ") " + response[i].text;
                    response[i].isNegative = zone.includes("-");
                }

                let negativeValuedZones: TimeZoneModel[] = filter(
                    response,
                    function (timeZone: TimeZoneModel) {
                        return timeZone.isNegative == true;
                    }
                );

                let postiveValuedZones: TimeZoneModel[] = filter(
                    response,
                    function (timeZone: TimeZoneModel) {
                        return timeZone.isNegative == false;
                    }
                );

                //sort values with minus in desc order and values with plus in asc order
                negativeValuedZones = orderBy(
                    negativeValuedZones,
                    (timeZone) => timeZone.nameWithZone,
                    "desc"
                );
                postiveValuedZones = orderBy(
                    postiveValuedZones,
                    (timeZone: any) => timeZone.nameWithZone,
                    "asc"
                );

                this.timeZoneList = negativeValuedZones.concat(postiveValuedZones);
            }
            catch (e) {
                console.error("Error in fetching timezone list");
                console.error(e);
            }
        },

        preselectDefaultValues() {
            let browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            let timezone = find(this.timeZoneList, function (timezone: TimeZoneModel) {
                return timezone.value == browserTimezone;
            });

            if (timezone != null) {
                this.serviceProvider.timeZone = browserTimezone;
            }

        },

        async loadData() {
            await this.fetchTimeZoneList();

            this.preselectDefaultValues();

        },

        loadTurnstileWidget() {
            // Wait for next tick to ensure DOM is ready
            this.$nextTick(() => {
                if ((window as any).turnstile) {
                    this.widgetId = (window as any).turnstile.render("#cf-turnstile", {
                        sitekey: import.meta.env.VITE_turnstileSiteKey,
                        theme: "dark",
                        callback: (token) => {
                            this.serviceProvider.turnstileToken = token;
                        },
                    });
                }
            });
        }
    },

    /************************************/
    /* Created Section *
      /************************************/

    created() {
        this.loadData();
    },

    mounted() {
        this.loadTurnstileWidget();
    },
});
